<template>
  <div>
    <alert-custom v-if="showAlert" :messageAlert="messageAlert">
      <template v-slot v-if="!!messageAlert.error">
        <p class="error-message">
          Se ha producido un error. Por favor, inténtalo de nuevo más tarde. <br><br>
          Si el problema persiste, escríbenos a 
          <b-link href="mailto:support@enviame.io" class="lnk lnk-forgot-mail">
            <a>support@enviame.io</a> 
          </b-link>
          para que podamos ayudarte.
        </p>
      </template>
    </alert-custom>
    <div id="container-recovery-password" class="animated fadeInDown" v-else>
      <div class="container-recovery-password-internal">
        <img src="@/assets/images/logo/logo_oficial.png" alt="Envíame" class="img-responsive"> 
          <h2 class="d-flex align-items-center">¿Olvidaste tu Contraseña?<feather-icon class="icon-lock" icon="LockIcon" size="20" stroke-width="3"/></h2>
          <p>Ingresa tu e-mail y te enviaremos instrucciones para recuperarla.</p>
          <validation-observer ref="recoveryForm" #default="{invalid}">
            <b-form class="form-forgot" @submit.prevent="validationForm">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-input-group class="input-group-merge mb-custom-forgot" :class="errors.length > 0 ? 'is-invalid':null">                       
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    :disabled="loading"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    class="form-control-merge custom-input-email"
                    />
                    <b-input-group-append is-text class="custom-input-email">
                        <feather-icon class="cursor-pointer" icon="XCircleIcon" @click="clean"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div id="recaptcha" class="center-recaptcha text-center"></div>

              <b-button variant="warning" class="btn-forgot" block type="submit" :disabled="invalid || loading">
                <feather-icon v-if="loading" icon="LoaderIcon" class="spinner"/>
                {{$t('Enviar mail de recuperación')}}
              </b-button>
            </b-form>
          </validation-observer>
          <b-link :to="{name:'auth-login'}" class="flex end-link-login lnk">
            <feather-icon icon="ChevronLeftIcon" class="mr-50" />
            <small>{{$t('Volver al login')}}</small>
          </b-link>
      </div> 
    </div>
  </div>
  
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BLink, BFormGroup, BFormInput, BForm, BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import LoginService from './login.service'
import { environment } from '@/environments/environment'
import AlertCustom from './AlertCustom.vue'

// eslint-disable-next-line no-undef
const googleRecaptcha = grecaptcha.enterprise

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    AlertCustom
  },
  data() {
    return {
      userEmail: '',
      loading: false,
      // validation
      required,
      attempt: 0,
      tokenRecaptcha: 'norendered',
      widget: null,
      email,
      loginService: new LoginService(this),
      showAlert: false,
      messageAlert: {},
      CONDITIONS: Object.freeze({
        SUCCESS: 'success',
        ERROR: 'error'
      })
    }
  },
  mounted () {
    this.showRecaptcha('recaptcha', this.attempt, 0)  
  },
  methods: {
    verifyCallback (token) {
      this.enableButton = true
      this.tokenRecaptcha = token
    },
    showRecaptcha (element, attempt, maxAttempt) {
      if (attempt >= maxAttempt) {
        this.enableButton = false
        if (this.tokenRecaptcha === 'norendered') {
          this.widget = googleRecaptcha.render(element, {
            'sitekey' : environment.recaptchaKey,
            'callback' : (token) => this.verifyCallback(token)
          })
        } else if (!this.widegt) googleRecaptcha.reset(this.widget)
        this.tokenRecaptcha = 'notoken'
      }
    },
    validationForm() {
      this.$refs.recoveryForm.validate().then(success => {
        if (success) {
          this.attempt++
          if (this.tokenRecaptcha === 'norendered') {
            this.loading = true
            this.recoveryPass()
          } else if (this.tokenRecaptcha === 'notoken') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title:'Oops',
                icon: 'warning',
                variant: 'danger',
                text: 'Debe marcar la casilla "No soy un robot"'
              }
            })
          } else {
            this.loading = true
            this.loginService.callService('validateRecaptcha', null, { response: this.tokenRecaptcha, expectedAction: 'recovery-password' })
              .then(resp => {
                if (resp.data.tokenProperties?.valid) {
                  this.recoveryPass()
                } else {
                  this.showRecaptcha('recaptcha', this.attempt, 0)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title:'Oops',
                      icon: 'warning',
                      variant: 'danger',
                      text: `Hemos detectado actividad sospechosa para recuperar tu cuenta. Por favor reintenta y si sigue pasando, contactate con nosotros. \n \n Problema: ${resp.reasons[0] || 'INVALID_REASON'}`
                    }
                  })
                  this.loading = false
                }
              })
              .catch(err => {
                this.showRecaptcha('recaptcha', this.attempt, 0)
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title:'Oops',
                    icon: 'warning',
                    variant: 'danger',
                    text: `Hemos detectado problemas con la verificación de reCAPTCHA. \n \n Problema: ${err}`
                  }
                })
              })
          }
        }
      })
    },
    recoveryPass () {
      this.$root.$data.auth.sendPasswordResetEmail(this.userEmail)
        .then((resp) => {
          this.showAlert = true
          this.messageAlert = {
            text: 'Verifica la bandeja de entrada para continuar con el proceso.',
            type: this.CONDITIONS.SUCCESS,
            title: 'Recuperación de Contraseña'
          }
        })
        .catch((error) => {
          this.showAlert = true
          this.messageAlert = {
            error: true,
            type: this.CONDITIONS.ERROR,
            title: 'Recuperación de Contraseña'
          }
          console.error(error)
        })
        .finally(_ => {
          this.showRecaptcha('recaptcha', this.attempt, 0)
          this.loading = false
          setTimeout(() => {
            this.showAlert = false
            this.$router.push({ name: 'auth-login' })
          }, 5000)
        })
    },
    clean () {
      this.userEmail = ''
    }
  }
}
</script>
<style lang="scss">
.mb-custom-forgot {
  margin-bottom: 15px;
}
.container-recovery-password-internal h2, .container-recovery-password-internal p{
  color: #18195E;
}
.container-recovery-password-internal h2 {
  margin-bottom: 1rem; 
  font-weight: 600;
}
.container-recovery-password-internal p {
  font-size: 13px;
}
.container-recovery-password-internal img  {
    margin-bottom: 2rem;
    width: 60%;
}
.custom-input-email, .custom-input-email:focus, .custom-input-email .input-group-text {
    background: #F0EFF8;
    color: #49454F;
}
.custom-input-email::placeholder { 
    color: #39549F; 
}
.flex {
    display: flex;
}
.center-recaptcha {
    justify-content: center;
    margin: 3rem 0;
}

.end-link-login small {
    font-weight: 500;
}
.end-link-login {
    color: #39549F;
    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
}    
.lnk-forgot-mail {
  color: #0000EE;
}
#container-recovery-password, #alert-recovery{
    background-color: #FFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 100vh;
}
.icon-lock {
    margin-left: 0.5rem;
    color: #F4BB43;
}
.form-forgot {
    margin-top: 2.8rem;
}
.container-recovery-password-internal {
    width: 450px;
}
.btn-forgot {
    background: linear-gradient(90deg, #F49143 -21.52%, #F4BB43 104.43%);
    color: #FFFFFF;
    border: none;
    height: 3rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
}
.btn-forgot:hover {
    background: linear-gradient(90deg, #f5852c -21.52%, #f7b52c 104.43%);
    color: #FFFFFF;
}
.error-message {
  font-size: 14px;
}
@media (max-width: 870px) {
    .container-recovery-password-internal {
        width: auto;
        padding: 0 2rem;
    }
    .container-recovery-password-internal h2 {
        font-size: 20px;
    }
    .center-recaptcha {
        margin: 2rem 0;
    }
    .mb-custom-forgot {
      margin-bottom: 0;
    }
    
}
    
</style>
